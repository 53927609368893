<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.teamMember.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.teamMember.list.title"></app-i18n>
      </h1>

      <app-team-member-list-toolbar></app-team-member-list-toolbar>
      <app-team-member-list-filter></app-team-member-list-filter>
      <app-team-member-list-table></app-team-member-list-table>
    </div>
  </div>
</template>

<script>
import TeamMemberListFilter from '@/modules/team-member/components/team-member-list-filter.vue';
import TeamMemberListTable from '@/modules/team-member/components/team-member-list-table.vue';
import TeamMemberListToolbar from '@/modules/team-member/components/team-member-list-toolbar.vue';

export default {
  name: 'app-team-member-list-page',

  components: {
    [TeamMemberListFilter.name]: TeamMemberListFilter,
    [TeamMemberListTable.name]: TeamMemberListTable,
    [TeamMemberListToolbar.name]: TeamMemberListToolbar,
  },
};
</script>

<style>
</style>
